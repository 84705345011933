import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { Box } from '@material-ui/core';
import styles from './Present.module.css';

interface ContentProps {
  close: () => void;
}

export function present(Content: React.FC<ContentProps>) {
  requestAnimationFrame(() => {
    const root = document.createElement('div');
    document.body.append(root);
    const close = () => unmountComponentAtNode(root);
    render(<Content close={close} />, root);
  });
}

export function confirm(message: string): Promise<boolean> {
  return new Promise((resolve) => {
    present(({ close }) => {
      const done = (result: boolean) => {
        resolve(result);
        close();
      };
      return (
        <div>
          <Modal open>
            <Box className={`${styles.centered} ${styles.modal}`}>
              <div className={styles.modalTitle}>{message}</div>
              <div className={styles.buttons}>
                <Button variant="contained" color="primary" onClick={() => done(true)}>
                  Yes
                </Button>
                <Button variant="contained" onClick={() => done(false)}>
                  Cancel
                </Button>
              </div>
            </Box>
          </Modal>
        </div>
      );
    });
  });
}
