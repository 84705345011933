// Generated by protoc-gen-grpc-ts-web. DO NOT EDIT!
/* eslint-disable */
/* tslint:disable */

import * as jspb from 'google-protobuf';
import * as grpcWeb from 'grpc-web';

import * as core from './Core_pb';
import * as googleProtobufWrappers from 'google-protobuf/google/protobuf/wrappers_pb';
import * as googleProtobufTimestamp from 'google-protobuf/google/protobuf/timestamp_pb';

export class TenantsService {

	private client_ = new grpcWeb.GrpcWebClientBase({
		format: 'text',
	});

	private methodInfoListTenants = new grpcWeb.MethodDescriptor<ListTenantsReq, ListTenantsRes>(
		"ListTenants",
		null,
		ListTenantsReq,
		ListTenantsRes,
		(req: ListTenantsReq) => req.serializeBinary(),
		ListTenantsRes.deserializeBinary
	);

	private methodInfoCreateTenant = new grpcWeb.MethodDescriptor<CreateTenantReq, CreateTenantRes>(
		"CreateTenant",
		null,
		CreateTenantReq,
		CreateTenantRes,
		(req: CreateTenantReq) => req.serializeBinary(),
		CreateTenantRes.deserializeBinary
	);

	private methodInfoUpdateTenant = new grpcWeb.MethodDescriptor<UpdateTenantReq, UpdateTenantRes>(
		"UpdateTenant",
		null,
		UpdateTenantReq,
		UpdateTenantRes,
		(req: UpdateTenantReq) => req.serializeBinary(),
		UpdateTenantRes.deserializeBinary
	);

	private methodInfoCreateTenantAdmin = new grpcWeb.MethodDescriptor<CreateTenantAdminReq, CreateTenantAdminRes>(
		"CreateTenantAdmin",
		null,
		CreateTenantAdminReq,
		CreateTenantAdminRes,
		(req: CreateTenantAdminReq) => req.serializeBinary(),
		CreateTenantAdminRes.deserializeBinary
	);

	constructor(
		private hostname: string,
		private defaultMetadata?: () => grpcWeb.Metadata,
	) { }

	listTenants(req: ListTenantsReq.AsObject, metadata?: grpcWeb.Metadata): Promise<ListTenantsRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = ListTenantsReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/Timeslice.Sdk.TenantsService/ListTenants',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoListTenants,
				(err: grpcWeb.Error, res: ListTenantsRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	createTenant(req: CreateTenantReq.AsObject, metadata?: grpcWeb.Metadata): Promise<CreateTenantRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = CreateTenantReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/Timeslice.Sdk.TenantsService/CreateTenant',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoCreateTenant,
				(err: grpcWeb.Error, res: CreateTenantRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	updateTenant(req: UpdateTenantReq.AsObject, metadata?: grpcWeb.Metadata): Promise<UpdateTenantRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = UpdateTenantReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/Timeslice.Sdk.TenantsService/UpdateTenant',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoUpdateTenant,
				(err: grpcWeb.Error, res: UpdateTenantRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	createTenantAdmin(req: CreateTenantAdminReq.AsObject, metadata?: grpcWeb.Metadata): Promise<CreateTenantAdminRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = CreateTenantAdminReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/Timeslice.Sdk.TenantsService/CreateTenantAdmin',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoCreateTenantAdmin,
				(err: grpcWeb.Error, res: CreateTenantAdminRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

}




export declare namespace ListTenantsReq {
	export type AsObject = {
		page?: core.PageQueryDTO.AsObject,
	}
}

export class ListTenantsReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, ListTenantsReq.repeatedFields_, null);
	}


	getPage(): core.PageQueryDTO {
		return jspb.Message.getWrapperField(this, core.PageQueryDTO, 1);
	}

	setPage(value?: core.PageQueryDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		ListTenantsReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): ListTenantsReq.AsObject {
		let f: any;
		return {
			page: (f = this.getPage()) && f.toObject(),
		};
	}

	static serializeBinaryToWriter(message: ListTenantsReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getPage();
		if (field1 != null) {
			writer.writeMessage(1, field1, core.PageQueryDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): ListTenantsReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new ListTenantsReq();
		return ListTenantsReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: ListTenantsReq, reader: jspb.BinaryReader): ListTenantsReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new core.PageQueryDTO();
				reader.readMessage(field1, core.PageQueryDTO.deserializeBinaryFromReader);
				message.setPage(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace ListTenantsRes {
	export type AsObject = {
		page?: core.PageDTO.AsObject,
		items: Array<TenantDTO.AsObject>,
	}
}

export class ListTenantsRes extends jspb.Message {

	private static repeatedFields_ = [
		2,
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, ListTenantsRes.repeatedFields_, null);
	}


	getPage(): core.PageDTO {
		return jspb.Message.getWrapperField(this, core.PageDTO, 1);
	}

	setPage(value?: core.PageDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	getItems(): Array<TenantDTO> {
		return jspb.Message.getRepeatedWrapperField(this, TenantDTO, 2);
	}

	setItems(value: Array<TenantDTO>): void {
		(jspb.Message as any).setRepeatedWrapperField(this, 2, value);
	}

	addItems(value?: TenantDTO, index?: number): TenantDTO {
		return jspb.Message.addToRepeatedWrapperField(this, 2, value, TenantDTO, index);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		ListTenantsRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): ListTenantsRes.AsObject {
		let f: any;
		return {
			page: (f = this.getPage()) && f.toObject(),
			items: this.getItems().map((item) => item.toObject()),
		};
	}

	static serializeBinaryToWriter(message: ListTenantsRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getPage();
		if (field1 != null) {
			writer.writeMessage(1, field1, core.PageDTO.serializeBinaryToWriter);
		}
		const field2 = message.getItems();
		if (field2.length > 0) {
			writer.writeRepeatedMessage(2, field2, TenantDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): ListTenantsRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new ListTenantsRes();
		return ListTenantsRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: ListTenantsRes, reader: jspb.BinaryReader): ListTenantsRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new core.PageDTO();
				reader.readMessage(field1, core.PageDTO.deserializeBinaryFromReader);
				message.setPage(field1);
				break;
			case 2:
				const field2 = new TenantDTO();
				reader.readMessage(field2, TenantDTO.deserializeBinaryFromReader);
				message.addItems(field2);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace CreateTenantReq {
	export type AsObject = {
		name: string,
		enabled: boolean,
	}
}

export class CreateTenantReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, CreateTenantReq.repeatedFields_, null);
	}


	getName(): string {return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setName(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	getEnabled(): boolean {return jspb.Message.getFieldWithDefault(this, 2, false);
	}

	setEnabled(value: boolean): void {
		(jspb.Message as any).setProto3BooleanField(this, 2, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		CreateTenantReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): CreateTenantReq.AsObject {
		let f: any;
		return {
			name: this.getName(),
			enabled: this.getEnabled(),
		};
	}

	static serializeBinaryToWriter(message: CreateTenantReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getName();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
		const field2 = message.getEnabled();
		if (field2 != false) {
			writer.writeBool(2, field2);
		}
	}

	static deserializeBinary(bytes: Uint8Array): CreateTenantReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new CreateTenantReq();
		return CreateTenantReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: CreateTenantReq, reader: jspb.BinaryReader): CreateTenantReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setName(field1);
				break;
			case 2:
				const field2 = reader.readBool()
				message.setEnabled(field2);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace CreateTenantRes {
	export type AsObject = {
		tenant?: TenantDTO.AsObject,
	}
}

export class CreateTenantRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, CreateTenantRes.repeatedFields_, null);
	}


	getTenant(): TenantDTO {
		return jspb.Message.getWrapperField(this, TenantDTO, 1);
	}

	setTenant(value?: TenantDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		CreateTenantRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): CreateTenantRes.AsObject {
		let f: any;
		return {
			tenant: (f = this.getTenant()) && f.toObject(),
		};
	}

	static serializeBinaryToWriter(message: CreateTenantRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getTenant();
		if (field1 != null) {
			writer.writeMessage(1, field1, TenantDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): CreateTenantRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new CreateTenantRes();
		return CreateTenantRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: CreateTenantRes, reader: jspb.BinaryReader): CreateTenantRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new TenantDTO();
				reader.readMessage(field1, TenantDTO.deserializeBinaryFromReader);
				message.setTenant(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace UpdateTenantReq {
	export type AsObject = {
		id: string,
		name?: googleProtobufWrappers.StringValue.AsObject,
		enabled?: googleProtobufWrappers.BoolValue.AsObject,
	}
}

export class UpdateTenantReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, UpdateTenantReq.repeatedFields_, null);
	}


	getId(): string {return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	getName(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 2);
	}

	setName(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 2, value);
	}

	getEnabled(): googleProtobufWrappers.BoolValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.BoolValue, 3);
	}

	setEnabled(value?: googleProtobufWrappers.BoolValue): void {
		(jspb.Message as any).setWrapperField(this, 3, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		UpdateTenantReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): UpdateTenantReq.AsObject {
		let f: any;
		return {
			id: this.getId(),
			name: (f = this.getName()) && f.toObject(),
			enabled: (f = this.getEnabled()) && f.toObject(),
		};
	}

	static serializeBinaryToWriter(message: UpdateTenantReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
		const field2 = message.getName();
		if (field2 != null) {
			writer.writeMessage(2, field2, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field3 = message.getEnabled();
		if (field3 != null) {
			writer.writeMessage(3, field3, googleProtobufWrappers.BoolValue.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): UpdateTenantReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new UpdateTenantReq();
		return UpdateTenantReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: UpdateTenantReq, reader: jspb.BinaryReader): UpdateTenantReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setId(field1);
				break;
			case 2:
				const field2 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field2, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setName(field2);
				break;
			case 3:
				const field3 = new googleProtobufWrappers.BoolValue();
				reader.readMessage(field3, googleProtobufWrappers.BoolValue.deserializeBinaryFromReader);
				message.setEnabled(field3);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace UpdateTenantRes {
	export type AsObject = {
		tenant?: TenantDTO.AsObject,
	}
}

export class UpdateTenantRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, UpdateTenantRes.repeatedFields_, null);
	}


	getTenant(): TenantDTO {
		return jspb.Message.getWrapperField(this, TenantDTO, 1);
	}

	setTenant(value?: TenantDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		UpdateTenantRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): UpdateTenantRes.AsObject {
		let f: any;
		return {
			tenant: (f = this.getTenant()) && f.toObject(),
		};
	}

	static serializeBinaryToWriter(message: UpdateTenantRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getTenant();
		if (field1 != null) {
			writer.writeMessage(1, field1, TenantDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): UpdateTenantRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new UpdateTenantRes();
		return UpdateTenantRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: UpdateTenantRes, reader: jspb.BinaryReader): UpdateTenantRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new TenantDTO();
				reader.readMessage(field1, TenantDTO.deserializeBinaryFromReader);
				message.setTenant(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace CreateTenantAdminReq {
	export type AsObject = {
		tenantId: string,
		email: string,
		userName: string,
	}
}

export class CreateTenantAdminReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, CreateTenantAdminReq.repeatedFields_, null);
	}


	getTenantId(): string {return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setTenantId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	getEmail(): string {return jspb.Message.getFieldWithDefault(this, 2, "");
	}

	setEmail(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 2, value);
	}

	getUserName(): string {return jspb.Message.getFieldWithDefault(this, 3, "");
	}

	setUserName(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 3, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		CreateTenantAdminReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): CreateTenantAdminReq.AsObject {
		let f: any;
		return {
			tenantId: this.getTenantId(),
			email: this.getEmail(),
			userName: this.getUserName(),
		};
	}

	static serializeBinaryToWriter(message: CreateTenantAdminReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getTenantId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
		const field2 = message.getEmail();
		if (field2.length > 0) {
			writer.writeString(2, field2);
		}
		const field3 = message.getUserName();
		if (field3.length > 0) {
			writer.writeString(3, field3);
		}
	}

	static deserializeBinary(bytes: Uint8Array): CreateTenantAdminReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new CreateTenantAdminReq();
		return CreateTenantAdminReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: CreateTenantAdminReq, reader: jspb.BinaryReader): CreateTenantAdminReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setTenantId(field1);
				break;
			case 2:
				const field2 = reader.readString()
				message.setEmail(field2);
				break;
			case 3:
				const field3 = reader.readString()
				message.setUserName(field3);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace CreateTenantAdminRes {
	export type AsObject = {
		userId: string,
	}
}

export class CreateTenantAdminRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, CreateTenantAdminRes.repeatedFields_, null);
	}


	getUserId(): string {return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setUserId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		CreateTenantAdminRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): CreateTenantAdminRes.AsObject {
		let f: any;
		return {
			userId: this.getUserId(),
		};
	}

	static serializeBinaryToWriter(message: CreateTenantAdminRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getUserId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
	}

	static deserializeBinary(bytes: Uint8Array): CreateTenantAdminRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new CreateTenantAdminRes();
		return CreateTenantAdminRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: CreateTenantAdminRes, reader: jspb.BinaryReader): CreateTenantAdminRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setUserId(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace TenantDTO {
	export type AsObject = {
		id: string,
		metadata?: core.MetadataDTO.AsObject,
		name: string,
		enabled: boolean,
	}
}

export class TenantDTO extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, TenantDTO.repeatedFields_, null);
	}


	getId(): string {return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	getMetadata(): core.MetadataDTO {
		return jspb.Message.getWrapperField(this, core.MetadataDTO, 2);
	}

	setMetadata(value?: core.MetadataDTO): void {
		(jspb.Message as any).setWrapperField(this, 2, value);
	}

	getName(): string {return jspb.Message.getFieldWithDefault(this, 3, "");
	}

	setName(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 3, value);
	}

	getEnabled(): boolean {return jspb.Message.getFieldWithDefault(this, 4, false);
	}

	setEnabled(value: boolean): void {
		(jspb.Message as any).setProto3BooleanField(this, 4, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		TenantDTO.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): TenantDTO.AsObject {
		let f: any;
		return {
			id: this.getId(),
			metadata: (f = this.getMetadata()) && f.toObject(),
			name: this.getName(),
			enabled: this.getEnabled(),
		};
	}

	static serializeBinaryToWriter(message: TenantDTO, writer: jspb.BinaryWriter): void {
		const field1 = message.getId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
		const field2 = message.getMetadata();
		if (field2 != null) {
			writer.writeMessage(2, field2, core.MetadataDTO.serializeBinaryToWriter);
		}
		const field3 = message.getName();
		if (field3.length > 0) {
			writer.writeString(3, field3);
		}
		const field4 = message.getEnabled();
		if (field4 != false) {
			writer.writeBool(4, field4);
		}
	}

	static deserializeBinary(bytes: Uint8Array): TenantDTO {
		var reader = new jspb.BinaryReader(bytes);
		var message = new TenantDTO();
		return TenantDTO.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: TenantDTO, reader: jspb.BinaryReader): TenantDTO {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setId(field1);
				break;
			case 2:
				const field2 = new core.MetadataDTO();
				reader.readMessage(field2, core.MetadataDTO.deserializeBinaryFromReader);
				message.setMetadata(field2);
				break;
			case 3:
				const field3 = reader.readString()
				message.setName(field3);
				break;
			case 4:
				const field4 = reader.readBool()
				message.setEnabled(field4);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}


function ListTenantsReqFromObject(obj: ListTenantsReq.AsObject | undefined): ListTenantsReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new ListTenantsReq();
	message.setPage(PageQueryDTOFromObject(obj.page));
	return message;
}

function PageQueryDTOFromObject(obj: core.PageQueryDTO.AsObject | undefined): core.PageQueryDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new core.PageQueryDTO();
	message.setPage(Int32ValueFromObject(obj.page));
	message.setPageSize(Int32ValueFromObject(obj.pageSize));
	return message;
}

function Int32ValueFromObject(obj: googleProtobufWrappers.Int32Value.AsObject | undefined): googleProtobufWrappers.Int32Value | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new googleProtobufWrappers.Int32Value();
	message.setValue(obj.value);
	return message;
}

function ListTenantsResFromObject(obj: ListTenantsRes.AsObject | undefined): ListTenantsRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new ListTenantsRes();
	message.setPage(PageDTOFromObject(obj.page));
	(obj.items || [])
		.map((item) => TenantDTOFromObject(item))
		.forEach((item) => message.addItems(item));
	return message;
}

function PageDTOFromObject(obj: core.PageDTO.AsObject | undefined): core.PageDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new core.PageDTO();
	message.setPage(obj.page);
	message.setPageSize(obj.pageSize);
	message.setTotalCount(obj.totalCount);
	return message;
}

function TenantDTOFromObject(obj: TenantDTO.AsObject | undefined): TenantDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new TenantDTO();
	message.setId(obj.id);
	message.setMetadata(MetadataDTOFromObject(obj.metadata));
	message.setName(obj.name);
	message.setEnabled(obj.enabled);
	return message;
}

function MetadataDTOFromObject(obj: core.MetadataDTO.AsObject | undefined): core.MetadataDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new core.MetadataDTO();
	message.setCreatedAt(TimestampFromObject(obj.createdAt));
	message.setUpdatedAt(TimestampFromObject(obj.updatedAt));
	return message;
}

function TimestampFromObject(obj: googleProtobufTimestamp.Timestamp.AsObject | undefined): googleProtobufTimestamp.Timestamp | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new googleProtobufTimestamp.Timestamp();
	message.setSeconds(obj.seconds);
	message.setNanos(obj.nanos);
	return message;
}

function CreateTenantReqFromObject(obj: CreateTenantReq.AsObject | undefined): CreateTenantReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new CreateTenantReq();
	message.setName(obj.name);
	message.setEnabled(obj.enabled);
	return message;
}

function CreateTenantResFromObject(obj: CreateTenantRes.AsObject | undefined): CreateTenantRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new CreateTenantRes();
	message.setTenant(TenantDTOFromObject(obj.tenant));
	return message;
}

function UpdateTenantReqFromObject(obj: UpdateTenantReq.AsObject | undefined): UpdateTenantReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new UpdateTenantReq();
	message.setId(obj.id);
	message.setName(StringValueFromObject(obj.name));
	message.setEnabled(BoolValueFromObject(obj.enabled));
	return message;
}

function StringValueFromObject(obj: googleProtobufWrappers.StringValue.AsObject | undefined): googleProtobufWrappers.StringValue | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new googleProtobufWrappers.StringValue();
	message.setValue(obj.value);
	return message;
}

function BoolValueFromObject(obj: googleProtobufWrappers.BoolValue.AsObject | undefined): googleProtobufWrappers.BoolValue | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new googleProtobufWrappers.BoolValue();
	message.setValue(obj.value);
	return message;
}

function UpdateTenantResFromObject(obj: UpdateTenantRes.AsObject | undefined): UpdateTenantRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new UpdateTenantRes();
	message.setTenant(TenantDTOFromObject(obj.tenant));
	return message;
}

function CreateTenantAdminReqFromObject(obj: CreateTenantAdminReq.AsObject | undefined): CreateTenantAdminReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new CreateTenantAdminReq();
	message.setTenantId(obj.tenantId);
	message.setEmail(obj.email);
	message.setUserName(obj.userName);
	return message;
}

function CreateTenantAdminResFromObject(obj: CreateTenantAdminRes.AsObject | undefined): CreateTenantAdminRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new CreateTenantAdminRes();
	message.setUserId(obj.userId);
	return message;
}

