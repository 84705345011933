import React from 'react';
import PeopleIcon from '@material-ui/icons/People';
import DashboardIcon from '@material-ui/icons/Dashboard';

const SidebarItems = [
  {
    name: 'Dashboard',
    route: '/dashboard',
    icon: <DashboardIcon />,
  },
  {
    name: 'Tenants',
    route: '/dashboard/tenants',
    icon: <PeopleIcon />,
  },
];

export default SidebarItems;
