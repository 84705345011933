import { Box, Button, Modal, Switch, Typography } from '@material-ui/core';
import { CellParams, DataGrid } from '@material-ui/data-grid';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { confirm } from '../../../Lib/components/Present';
import useOnceAsync from '../../../Lib/hooks/UseOnceAsync';
import { TenantDTO } from '../../../Lib/sdk/Tenants_pb';
import LoadingIndicator from '../../../Lib/ui-kit/LoadingIndicator';
import grpc from '../../grpc';
import { Form } from './Form/Form';
import styles from './TenantsList.module.css';

export const TenantsList = observer(() => {
  const [modalOpen, setModalOpen] = useState(false);

  const { value, loading, error, refresh } = useOnceAsync(async () => {
    return await grpc.tenants.listTenants({});
  });

  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.3 },
    { field: 'name', headerName: 'Name', flex: 0.5 },
    {
      field: 'enabled',
      headerName: 'Enabled',
      disableClickEventBubbling: true,
      renderCell: (params: CellParams) => {
        const data = params.data as TenantDTO.AsObject;
        return (
          <Switch
            name="enabled"
            checked={data.enabled}
            onChange={async () => {
              if (await confirm('Are you sure?')) {
                await grpc.tenants.updateTenant({
                  id: data.id,
                  enabled: {
                    value: !data.enabled,
                  },
                });
                await refresh();
              }
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <Typography variant="h3">Tenants</Typography>

      <div className={styles.centered}>
        <LoadingIndicator loading={loading} error={error} />
      </div>

      {value && (
        <>
          <div className={styles.dataTable}>
            <DataGrid rows={value.items} columns={columns} />
          </div>
          <div>
            <Button variant="contained" color="secondary" onClick={() => setModalOpen(true)}>
              <PersonAddIcon style={{ marginRight: '5px' }} />
              Create Tenant
            </Button>
          </div>
          <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
            <Box className={`${styles.centered} ${styles.modal}`}>
              <div className={styles.modalTitle}>Create Tenant</div>
              <Form
                onCreated={() => {
                  setModalOpen(false);
                  refresh();
                }}
              />
            </Box>
          </Modal>
        </>
      )}
    </>
  );
});
