import { observer } from 'mobx-react';
import * as React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { sharedUserService } from '../../../Lib/authentication/UserService';
import LoginCard from '../../../Lib/ui-kit/LoginCard';
import styles from './Login.module.css';

const Login = () => {
  const history = useHistory();
  return (
    <div className={styles.center}>
      {sharedUserService.token ? (
        <Redirect to="/dashboard" />
      ) : (
        <LoginCard
          onLogin={async (username: string, password: string) => {
            await sharedUserService.signIn(username, password);
            history.push('/dashboard');
          }}
        />
      )}
    </div>
  );
};

export default observer(Login);
