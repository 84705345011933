import { metadata, registerDevTools } from '../Lib/grpc';
import { TenantsService } from '../Lib/sdk/Tenants_pb';
import { environment } from '../Lib/utils/Environment';

const host = environment.apiUrl;

const grpc = {
  tenants: new TenantsService(host, metadata),
};

registerDevTools(grpc);

export default grpc;
