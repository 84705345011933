import * as React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { urljoin } from '../../Lib/utils/urljoin';
import Sidebar from '../components/Sidebar';
import Dashboard from '../pages/Dashboard/Dashboard';
import { AuthRoute } from '../../Lib/components/AuthRoute';
import { TenantsList } from '../pages/TenantsList/TenantsList';

export default function DashboardRouter() {
  const { path } = useRouteMatch();
  return (
    <>
      <Sidebar />
      <div id="main">
        <AuthRoute exact path={urljoin(path, '')} component={Dashboard} />
        <AuthRoute exact path={urljoin(path, 'tenants')} component={TenantsList} />
      </div>
    </>
  );
}
