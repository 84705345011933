import { Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';

const Dashboard = () => {
  return (
    <>
      <Typography variant="h3">Dashboard</Typography>
    </>
  );
};

export default observer(Dashboard);
