import React from 'react';
import styles from './Form.module.css';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import { observable, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Switch } from '@material-ui/core';
import grpc from '../../../grpc';

interface Props {
  onCreated(): void;
}

@observer
export class Form extends React.Component<Props> {
  @observable
  name = '';

  @observable
  enabled = true;

  @observable
  formSuccess = false;

  @computed get submitDisabled() {
    return !this.name;
  }

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  formSubmit = async () => {
    const response = await grpc.tenants.createTenant({
      name: this.name,
      enabled: this.enabled,
    });
    if (response.tenant) {
      this.formSuccess = true;
      this.props.onCreated();
    }
  };

  render() {
    return (
      <div className={styles.outerBox}>
        <form>
          <div className={styles.form}>
            <div>
              <InputLabel>Name</InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                value={this.name}
                onChange={(event) => (this.name = event.currentTarget.value)}
              />
            </div>
            <div>
              <InputLabel>Enabled</InputLabel>
              <Switch
                checked={this.enabled}
                onChange={() => (this.enabled = !this.enabled)}
                name="enabled"
              />
            </div>
          </div>
          <div className={styles.submit}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={this.formSubmit}
              disabled={this.submitDisabled}
            >
              Submit
            </Button>
          </div>
          {this.formSuccess && (
            <div className={styles.successText}>New tenant created successfully.</div>
          )}
        </form>
      </div>
    );
  }
}
