require('typeface-roboto');
import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Redirect, Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import DashboardRouter from './navigation/DashboardRouter';
import { AuthRoute } from '../Lib/components/AuthRoute';
import Login from './pages/Login/Login';
import { environment } from './utils/Environment';

if (environment.sentry.enabled && environment.sentry.dsn) {
  Sentry.init({
    dsn: environment.sentry.dsn,
    environment: environment.environment,
    integrations: [new Integrations.BrowserTracing({})],
    attachStacktrace: true,
  });
}

ReactDOM.render(
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <BrowserRouter>
      <Switch>
        <Redirect exact from="/" to="/login" />
        <Route exact path="/login" component={Login} />
        <AuthRoute path="/dashboard" component={DashboardRouter} />
      </Switch>
    </BrowserRouter>
  </MuiPickersUtilsProvider>,
  document.getElementById('root'),
);
