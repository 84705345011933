import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import SidebarItems from './SidebarItems';
import styles from './Sidebar.module.css';
import { sharedUserService } from '../../Lib/authentication/UserService';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const Sidebar = () => {
  const history = useHistory();

  return (
    <>
      <div className={styles.sidebar}>
        <h2 className={styles.logo}>Bookm Superadmin</h2>
        {SidebarItems.map((item, index) => (
          <NavLink
            to={item.route}
            activeClassName={item.route === '/' ? '' : 'active'}
            className={styles.link}
            key={index}
          >
            <div className={styles.sidebarItem} key={item.name}>
              <div className={styles.itemGrid}>
                <span>{item.icon ? item.icon : ''}</span>
                <span>{item.name}</span>
              </div>
            </div>
          </NavLink>
        ))}
        <NavLink
          to="/login"
          activeClassName={'active'}
          className={styles.logout}
          onClick={async () => {
            await sharedUserService.signOut();
            history.push('/login');
          }}
        >
          <div className={styles.sidebarItem} key="logout">
            <div className={styles.itemGrid}>
              <span>
                <AccountCircleIcon />
              </span>
              <span>Logout</span>
            </div>
          </div>
        </NavLink>
      </div>
    </>
  );
};

export default Sidebar;
