/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const environment = {
  apiUrl: process.env.API_URL!,
  version: process.env.VERSION!,
  environment: process.env.ENVIRONMENT ?? 'development',
  sentry: {
    enabled: process.env.SENTRY_ENABLED ?? false,
    dsn: process.env.SENTRY_DSN,
  },
};
